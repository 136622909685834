<script>
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'
import { defineAsyncComponent } from '@vue/runtime-core'

// Load layout components dynamically.
const requireContext = require.context('@/pages/system/steps/config', false, /.*\.vue$/, 'sync')
const componentNames = requireContext.keys().map((file) => file.replace(/(^.\/)|(\.vue$)/g, ''))
const components = {}
componentNames.forEach((component) => { // component represents the component name
  components[component] = defineAsyncComponent({
    loader: () => import('@/pages/system/steps/config/' + component + '.vue'),
    delay: 0
  })
})
export default {
  components,
  setup () {
    const store = useStore()

    const currentStep = computed(() => store.getters['system/currentStep'])
    const currentConfigType = computed(() => {
      if (currentStep.value) {
        return slugify(currentStep.value.config + '_Option')
      }
      return slugify('mainOption')
    })
    // methods
    function slugify (str) {
      if (typeof str !== 'undefined') {
        return str.toLowerCase()
          // Replaces any - or _ characters with a space
          .replace(/[-_]+/g, ' ')
          // Removes any non alphanumeric characters
          .replace(/[^\w\s]/g, '')
          // Uppercases the first character in each group immediately following a space
          // (delimited by spaces)
          .replace(/ (.)/g, function ($1) { return $1.toUpperCase() })
          // Removes spaces
          .replace(/ /g, '')
      }
    }

    function onChange (e) {
      store.dispatch('system/changeConfigShow', {
        config: currentStep.value.config,
        checked: e.target.value
      })
    }

    return {
      currentStep,
      currentConfigType,
      onChange
    }
  }
}
</script>
<template>
  <div v-if="currentStep" class="row">
    <div class="col-md-12">
      <h3>Brauchen Sie ein {{ currentStep.name }}?</h3>
      <div class="configurator-body">
        <div class="form-check">
          <input id="custom-checkbox_1" type="radio" class="form-check-input" name="show" value="0" @click="onChange" :checked="!currentStep.show">
          <label for="custom-checkbox_1" class="form-check-label">Ich brauche kein {{ currentStep.name }}</label>
        </div>
        <div class="form-check">
          <input id="custom-checkbox_2" type="radio" class="form-check-input" name="show" value="1" @click="onChange" :checked="currentStep.show">
          <label for="custom-checkbox_2" class="form-check-label">Ich brauche ein {{ currentStep.name }}</label>
        </div>
      </div>
      <Component v-if="currentConfigType && currentStep.show" :config="currentStep.config" :is="currentConfigType" />
    </div>
  </div>
</template>
